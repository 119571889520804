import {useEffect,useState } from 'react';
import { Modal } from 'react-bootstrap';

import Gateway from './lib/gateway.js';
import RPC from './lib/rpc.js';
import Loader from './lib/loader.js';
import STORAGE from './lib/storage.js';
import Tools from './lib/tools.js';
import Funs from './lib/funs.js';

import Login from './common/login';
import User from './common/user';
import Server from './common/server';

import Version from './common/version';

import Sign from './common/sign';
import Password from './common/password';

import { Keyring } from '@polkadot/api';
import { mnemonicGenerate } from '@polkadot/util-crypto';

//const anchorJS = window.AnchorJS;   
//const dot=window.Polkadot;
//const Keyring=dot.Keyring;

//test部分
//import Test_vSocial from './test/vSocial';
//import Test_vSaying from './test/vSaying';

/************************************/
/************启动配置部分**************/
/************************************/

const CFILE='config.deploy.js';            //默认配置文件
//const CFILE='config.js';
STORAGE.setMap({                    //设置保存在localstorage的键值
    "signature":"polka_sign",       //保存波卡签名的键名
    "start":"start_key",            //启动参数的键名
});
let runner=Funs.getAccount(CFILE);  //freeSaying的运行者,在发布内容时需要判断
let start=Funs.getStart(CFILE);     //App的启动配置，会保存在localstorage
start.account=runner.address;       //同步runner和start的账号信息
console.log(start.account);

/***********************************/
/**********模拟器本地缓存*************/
/***********************************/

const SIMULATOR=false;   //是否使用模拟器的开关，更方便的调试
if(SIMULATOR){
    STORAGE.setMap({ 
        "simulator":"app_key",      //保存在localstorage的freeSaying
        "sim_css":"css_lib",        //保存css的key
        "sim_js":"js_lib",          //保存js的key
    });
}

/***********************************/
/********freeSaying 主程序***********/
/***********************************/


let cur='';             //保存当前页面信息
let showing=false;      //弹出页面是否在显示
let queue={};           //close dialog进行回调的列表，当dailog被点击空白关闭后，调用这的方法

//统一的error code的定义
const errCode={
    "NO_PRIVATE_ACCOUNT":{message:"No account",code:44},
    //"FREE_NOT_ON_CHAIN":{message:"No account",code:44},
};

function App(props) {
    let [dialog, setDialog] = useState('');     //Page页面内容，主要是server和user两个页面
    let [block, setBlock] = useState(0);        //footer部分的freeSaying所在的block值
    let [owner, setOwner] = useState("");       //footer部分的freeSaying所有者信息
    let [details, setDetails] = useState('');   //加载过程中的提示信息
    let [content, setContent] = useState('');   //modal对话框内容
    let [show, setShow] = useState(false);      //modal显示状态变量
    let [title, setTitle] = useState('');       //modal的显示标题内容

    let [closeStyle,setCloseStype]=useState({});    //左侧的UI需要调整的样式
    let [pagesStyle,setPagesStype]=useState({});    //右侧的pages需要调整的样式

    const self = {
        /************************************************/
        /***************供cApp调用的方法部分****************/
        /************************************************/

        //验证账号，弹出输入密码的dialog窗口
        verify: (len,ck) => {
            if(!self.login()){
            //if (STORAGE.getKey("signature")===null){
                return ck && ck(self.message("NO_PRIVATE_ACCOUNT"));
            } else {
                self.setQueue('verify',ck);
                
                //FIXME:这里是个经验值，基础写链的费用，需要进行处理
                const base=153;         
                const cost=parseFloat((len+base)*0.01).toFixed(3);
                setContent(
                    (< Sign
                        callback={
                            (pair) => {
                                var skip=!pair?false:true;
                                self.handleClose(skip);
                                self.removeQueue('verify');
                                ck && ck(pair);
                            }
                        }
                        balance={RPC.common.balance}
                        cost={cost}
                    />)
                );
                setTitle((<span className="text-muted" >Verifying</span>));
                self.handleShow();
            }
        },

        //评论功能的实现，可以去除，直接使用gateway的auto实现，或者，到extra里去找
        comment:(ctx,anchor,block,ck)=>{
            //const account=STORAGE.getKey("signature");
            Gateway.set.account(runner.address);
            Gateway.set.endpoint(start.server);
            const svc="vSaying";
            const fun="comment";
            const params={
                anchor:anchor,
                block:block,
                comment:ctx,
            }
            Gateway.common.auto(svc,fun,params,(res)=>{
                ck && ck(res);
            });
        },

        //注册时候设置密码，弹出注册的dialog，需要和下面的register进行合并
        register:()=>{
            const mnemonic = mnemonicGenerate(12,true);
            console.log(mnemonic);
            const keyring = new Keyring({ type: 'sr25519' });
            const pair = keyring.addFromUri(mnemonic);
            //const ss58Address = pair.address;
            setContent(
                (< Password callback={(pass) => {
                    self.handleClose();
                    const sign=pair.toJson(pass);
                    sign.meta.name=Funs.randomName();
                    STORAGE.setKey("signature",sign);
                    self.fresh();
                }} account={pair.address} mnemonic={mnemonic}/>)
            );
            setTitle((<span className="text-warning" >New account</span>));
            self.handleShow();
        },

        /************************************************/
        /*******************程序显示部分*******************/
        /************************************************/

        load:(page) => {
            if(cur===page && showing){
                return self.hideDialog();
            }
            cur=page;   //设置当前页面

            switch (page) {
                case 'server':
                    setDialog(<Server fresh={self.fresh} clean={self.clean}/>);
                    break;
                case 'version':
                    //console.log(start);
                    setDialog(<div>
                            <Version block={start.block} app={start.app} />
                            <Server fresh={self.fresh} clean={self.clean}/>
                        </div>);
                    break;
                case 'account':
                    if(self.login()){
                        setDialog((<User fresh={self.fresh} balance={RPC.common.balance} auto={RPC.common.auto}/>));
                    }else{
                        setDialog(
                            (<Login fresh={self.fresh} register={self.register}/>)
                        );
                    }
                    break;
                default:
                    break;
            }
            
            self.showDialog();
        },
        //检查用户是否已经登录
        login:()=>{
            return STORAGE.getKey("signature")===null?false:true;
        },
        
        checkAnonymous:()=>{
            RPC.start.anonymous=!!runner.meta.public;
        },

        //显示最上层的page，账号及设置页面的信息
        showDialog:()=>{
            showing=true;
            self.showClose();
            document.getElementById('free_dialog').style.display="block";
        },
        hideDialog:()=>{
            showing=false;
            self.hideClose();
            document.getElementById('free_dialog').style.display="none";
        },
        handleClose: (skip) => {
            setShow(false);
            if(!skip){
                for(var k in queue) queue[k](false);
            }
        },
        handleShow: () => { setShow(true); },

        //左侧肩部的close显示及回调方法的处理
        showClose:()=>{
            document.getElementById('close').style.display="block";
        },
        hideClose:()=>{
            document.getElementById('close').style.display="none";
        },
        setQueue:(name,fun)=>{
            queue[name]=fun;
        },
        removeQueue:(name)=>{
            delete queue[name];
        },
        
        //显示加载内容的方法
        info:(txt)=>{
            const map={"paddingTop":"60px",};
            return (<div className="row" style={map}><div className="col-12 text-center">{txt}</div></div>)
        },

        /************************************************/
        /******************程序加载逻辑部分*****************/
        /************************************************/

        cleanStart:()=>{
            STORAGE.removeKey("start");
        },
        updateStart:()=>{
            return STORAGE.setKey("start",start);
        },

        freshRunner:(ck)=>{
            runner=Funs.getAccount(CFILE);    //freeSaying的运行者,在发布内容时需要判断
            start.account=runner.address;
            Gateway.set.account(start.account);
            Gateway.set.spam(()=>{
                self.updateStart();
                self.checkAnonymous();
                RPC.init(start,{websocket:true},(res)=>{
                    ck && ck(res);
                });
            });
        },
        clean:()=>{
            self.cleanStart();
            start=Funs.getStart(CFILE);
            start.account=runner.address;
            self.hideDialog();
            self.entry();
        },
        fresh:(obj)=>{
            if(obj){
                start.gateway=obj.gateway;
                start.node=obj.node;
                if(obj.gateway) start.server=obj.server;
            }
            self.hideDialog();
            self.freshRunner(()=>{
                self.entry();
            });
        },
        entry:(ck)=>{
            //console.log(JSON.stringify(start));
            self.updateStart();
            if(SIMULATOR) return self.loadFromLocal(()=>{
                RPC.init(start,{websocket:true},(res)=>{
                    ck && ck(res);
                });
            });

            if(start.gateway){
                self.loadFromGateway(()=>{
                    RPC.init(start,{websocket:true},(res)=>{
                        ck && ck(res);
                    });
                });
            }else{
                self.loadFromNode(()=>{

                });
            }
        },

        progress:(list,status)=>{
            let dom='<div id="progress"><table>';
            for(let i=0;i<list.lengthl;i++){
                const row=list[i];
                dom+=`<tr>
                    <td>${row}</td>
                    <td>${status[i]?'Done':'...'}</td>
                </tr>`;
            }
            dom+='</table></div>';
            setDetails((<div className="row">{dom}</div>));
            return true;
        },
        changeUI:()=>{
            //console.log('ready to modify UI css');
            setCloseStype({"background":"#EEEEEE"});
            setPagesStype({"background":"#EEEEEE"});
        },
        loadFromNode:(ck)=>{
            setDetails(self.info(`Linking to chain : ${start.node}`));
            Gateway.set.init();
            RPC.init(start,{websocket:true},function(err){
                self.checkAnonymous();
                //if(!RPC.ready) return setTimeout(self.loadFromNode, 50);
                //从这里开始，先处理获取freeConfig,看是不是要运行指定版本
                setDetails(self.info('Checking app on chain...'));
                RPC.common.search(start.app,function(res){
                    if(!res){
                        setDetails(self.info('FreeSaying is not on target chain.'));
                        return ck && ck();
                    }

                    self.showOwner(res.owner===null?'':res.owner,parseInt(res.block));

                    if (res.protocol && res.protocol.lib) {
                        setDetails(self.info('Loading support lib ...'));
                        Loader(
                            res.protocol.lib,
                            {search: RPC.common.search,target:RPC.common.target},
                            (code) => {
                                //console.log(code);
                                //setDetails(self.info('Loading application ...'));
                                if(code.error){
                                    const flib=[];
                                    for(var k in code.failed) flib.push(k);
                                    setDetails(self.info(`Failed to load support lib. ${JSON.stringify(flib)}`));
                                    return false;
                                }
                                self.loadApp(code,res.raw,ck);
                            }
                        );
                    } else {
                        self.loadApp({},res.raw,ck);
                    }
                });
            });
        },
        loadFromGateway:(ck)=>{
            RPC.init(start,{websocket:false},()=>{
                self.checkAnonymous();
                const anchor=start.app;
                RPC.common.search(anchor,function(res){
                    if(!res){
                        setDetails(self.info('FreeSaying is not on target chain.'));
                        return ck && ck();
                    }

                    const block=parseInt(res.block);
                    self.showOwner(res.owner===null?'':res.owner,block);
                    //1.需要加载库的操作部分;
                    if(res.protocol && res.protocol.lib){
                        setDetails(self.info('Loading support lib ...'));
                        const params={anchor:anchor,block:block};
                        return RPC.common.auto('vHistory','lib',params,(code)=>{
                            if(!code){
                                setDetails(self.info(`Failed to load support lib. ${JSON.stringify(res.protocol.lib)}`));
                                return ck && ck();
                            } 
                            
                            //1.1.检查加载失败的lib
                            const flib=[];
                            if(code.failed){
                                for(var k in code.failed){
                                    flib.push(k)
                                }
                            }
                            if(flib.length!==0){
                                setDetails(self.info(`Failed to load support lib. ${JSON.stringify(flib)}`));
                                return ck && ck();
                            } 

                            self.loadApp(code,res.raw,ck);
                        });
                    }

                    //2.不需要加载额外的库的情况
                    self.loadApp({},res.raw,ck);
                });
            });
        },
        showOwner:(owner,block)=>{
            setBlock(block.toLocaleString());
            setOwner(owner);
        },
        loadApp:(code,raw,ck)=>{
            setDetails(self.info('Loading application ...'));
            Funs.autoLoad(code,raw,RPC,(done)=>{
                if(!done){
                    setDetails(self.info('Failed to decode application.'));
                    return ck && ck();
                }

                self.changeUI();
                ck && ck();
            });
        },

        loadFromLocal:(ck)=>{
            console.log('here');
            RPC.init(start,{websocket:false},()=>{
                self.checkAnonymous();
                const raw=STORAGE.getKey("simulator");
                if(!raw) setDetails(self.info('No local simulator.'));
                self.showOwner('simulator',0);
                self.loadApp({},raw,ck);
            });
        },

        /************************************************/
        /******************出错信息处理部分*****************/
        /************************************************/

        message:(code)=>{
            const info=!code?{message:"Unexpected error",code:9488}:errCode[code];
            return {
                error:true,
                message:info.message,
                code:info.code,
            }
        },
    };

    
    //会被多次加载，但是放在useEffect里的话，setExtra部分没有正确执行
    Gateway.set.account(start.account);
    Gateway.set.endpoint(start.server);
    RPC.setExtra('auto',Gateway.common.auto);
    RPC.setExtra('verify', self.verify);
    RPC.setExtra('comment', self.comment);
    RPC.setExtra('reg',self.register);

    //页面启动后的部分
    //FIXME:userEffect后面传入的的空数组，去除掉。或者，搞清楚原因，记得拿掉会出错
    useEffect(() => {
        self.entry(()=>{
            //Test_vSocial.single('vSocial');
            //Test_vSocial.accounts();
            //Test_vSocial.flip();
            //Test_vSocial.accountsToChain()
            //Test_vSocial.favs();
            //Test_vSocial.fav();

            //Test_vSaying.counts();
        });
    }, []);

    return (<div>
        <div id="close" style={closeStyle}>
            <span onClick={() => {self.hideDialog()}}><img alt="" src="close.svg" /></span>
        </div>
        <div id="pages" style={pagesStyle}>
            <span onClick={() => {self.load("version")}}><img className='icon' alt="" src="server.svg" /></span>
            {/* <span onClick={() => {self.load("server")}}><img className='icon' alt="" src="server.svg" /></span> */}
            <span onClick={() => {self.load("account")}}><img className='icon' alt="" src="setting.svg" /></span>
        </div>
        <div id="container">{details}</div>
        <div id="free_dialog">{dialog}</div>
        <div id="free_footer">Base on Anchor network. Owned by {!owner?"":Tools.shortenAddress(owner,9)} <br/>Anchor name : {start.app} , raw data on block {block}</div>
        <Modal show={show} onHide={self.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title > {title} </Modal.Title>
            </Modal.Header>
            <Modal.Body >{content}</Modal.Body>
        </Modal>
     </div>);
}

export default App;