import { Row, Col,Form,Button } from 'react-bootstrap';
import { useState } from 'react';

import STORAGE from '../lib/storage.js';

function Simulator(props) {
	
	let [file, setFile] = useState('');

	const self={
    	load:()=>{
			console.log(file);
			STORAGE.setKey("simulator",file);
		},
		changeFile:(ev)=>{
			try {

				const fa = ev.target.files[0];
				const reader = new FileReader();
				reader.onload = (e)=>{
					setFile(e.target.result);
				};
				reader.readAsText(fa);
			}catch (error) {
				console.log(error)
			}
		  },
	}
	return (
		<Row >
			<Col lg = { 12 } xs = { 12 } className="pt-4"><hr /></Col>
			<Col lg = { 12 } xs = { 12 } className="pt-4">
				<small>Load cApp code from local file...</small>
				<Form.Control size = "md" type = "file" onChange = { self.changeFile }/>
			</Col>
			<Col lg = { 7 } xs = { 7 } className = "pt-2 text-end" ></Col>
			<Col lg = { 5 } xs = { 5 } className = "pt-2 text-end" >
				<Button size = "md" variant="primary" onClick={()=>{self.load()}}>Load</Button>
			</Col>
		</Row>
	);
}

export default Simulator;