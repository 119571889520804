exports.start= {
    account: '',                    //使用的连接账号
    anonymous:true,                 //匿名的话，就是使用公用账号来请求        
    node:'wss://dev.metanchor.net',  //连接的入口node
    entry: 'freeNodes',             //获取nodes列表的anchor名称
    app:"freeSaying",               //cApp的anchor名称  
    gateway:true,                   //是否通过gateway的方式访问freeSaying         
    server: 'http://android.im/vGateway',       //gateway的URI
};

//公共账号配置文件
exports.account={
    address:"5Gk5U5qbYbMuvxttahwMQeYesfWKf9JXLzqgKuVjZHLeYP74",
    encoded:"T6HqHXn+5QMhDaPOcoiWxhxkwyKWhGmnhSENLqYpFucAgAAAAQAAAAgAAAA5PTuY73xw4gaXVwyuuVORa1Ju68ETfTClpX5hwtu3keJTJNNE8aWR1sB/Rn+G0bfyogjlDsLQhsrx/jeOnO1WK6nY8NGzWYizCeCAAwS+bSCirEnlagascLKkUUNb7m7HUiSfz8A1pMqcwaXtL/EkUn4dl2R2R3dW1BcWwS73cDxCarhn+ZdgkyzH7w/44ZF/mkhqd0sTIWMWSC6p",
    encoding:{
        content:["pkcs8","sr25519"],
        type:["scrypt","xsalsa20-poly1305"],
        version:"3",
    },
    meta:{
        name:"pulicAccount",
        public:true,
    },
    password:"123456",
};