
import { useState, useEffect } from 'react';

import { Container, ListGroup, Row, Col } from 'react-bootstrap';
import RPC from '../lib/rpc.js';

function Version(props) {
	let [list, setList] = useState([]);

	const shorten = (address, n) => {
		if (n === undefined) n = 10;
		return address.substr(0, n) + '...' + address.substr(address.length - n, n);
	};

	useEffect(() => {
		console.log(props.app);
		const cur = parseInt(props.block);
		RPC.common.history(props.app, (res) => {
			const vers = [];
			for (let i = 0; i < res.length; i++) {
				const row = res[i];
				vers.push({
					"block": row.block,
					"ver": (row.protocol && row.protocol.ver ? row.protocol.ver : "unkown"),
					"owner": row.signer,
					"active": (cur === 0 ? (i === 0 ? true : false) : (parseInt(row.block) === cur ? true : false)),
				});
			}
			setList(vers);
		});
	}, [props.app]);

	return (
		<Container>
			<Row className="pt-2">
				<Col lg={12} xs={12}><h6>Versions Selector</h6></Col>
				<Col lg={12} xs={12}>
					<ListGroup as="ol">
						{list.map((item, index) => (
							<ListGroup.Item as="li" active={item.active} key={index}>
								{item.ver} on block {item.block.toLocaleString()}, owner {shorten(item.owner, 6)}
							</ListGroup.Item >
						))}
					</ListGroup>
				</Col>
			</Row>
		</Container>
	);
}
export default Version;