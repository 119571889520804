import Gateway from '../lib/gateway';
import TestV from './common';

const self={
    favs:()=>{
        var auto=Gateway.common.auto;
        var svc='vSocial',act='favs';
        var list=[['testme',73],['hello',546]];
        auto(svc,act,{list:JSON.stringify(list)},(res)=>{
            console.log(res);
        });
    },
    fav:()=>{
        var auto=Gateway.common.auto;
        var svc='vSocial',act='single';
        var anchor='testme',block=79;
        auto(svc,act,{anchor:anchor,block:block},(res)=>{
            console.log(res);
        });
    },
    single:(svc)=>{
        console.log(`------------------------- single test start -------------------------`);
        TestV.backup();
        var testme_bk=73;
        var ts={
            'vSocial':[
                //{fun:'unfav',params:{anchor:'testme',block:testme_bk},account:'auto'},
                {fun:'tread',params:{anchor:'testme',block:testme_bk},account:'auto'},
                {fun:'tread',params:{anchor:'testme',block:testme_bk},account:'5ECZb1Jmm8ACGXdXtBx9AbqspK2ECQ1QNnXqH9FiGLEEjJjV'},
                //{fun:'unfav',params:{anchor:'testme',block:testme_bk},account:'5ECZb1Jmm8ACGXdXtBx9AbqspK2ECQ1QNnXqH9FiGLEEjJjV'},
            ],
        };

        if(!ts[svc]) return console.log(`No ${svc} test to run`);

        var list=ts[svc];
        var accs=[];
        for(var i=0;i<list.length;i++){
            var row=list[i];
            accs.push((!row.account || row.account==='auto')?Gateway.test.getAccount():row.account);
        }
        console.log(`Ready to get accounts spam : ${JSON.stringify(accs)}`);
        TestV.cacheSpam(accs,(map)=>{
            console.log(`Got accounts spam : ${JSON.stringify(map)}`);
            var auto=Gateway.common.auto;
            for(var i=0;i<list.length;i++){
                var row=list[i];
                var act=row.fun,params=row.params;
                var ss58=(!row.account || row.account==='auto')?TestV.getBackupAccount():row.account;
                Gateway.test.setSpam(ss58,map[ss58]);
                auto(svc,act,params,(res)=>{
                    console.log(res);
                });
            }

            TestV.recover();
            console.log(`------------------------- single test over -------------------------`);
        });
    },


    //测试vSocial对账号进行compress的性能，平均1～2ms请求一下
    //需要保障所有的账号都能被记录下来
    accounts:()=>{
        console.log(`------------------------- accounts test start -------------------------`);
        TestV.backup();
        var accs=TestV.accounts(200);
        TestV.cacheSpam(accs,(map)=>{
            //console.log(`Account /Spam :${JSON.stringify(map)}`);
            var auto=Gateway.common.auto;

            var svc='vSocial',act='fav';
            var anchor='testme',block=89;
            for(var ss58 in map){
                Gateway.test.setSpam(ss58,map[ss58]);
                auto(svc,act,{anchor:anchor,block:block},(res)=>{
                    console.log(res);
                });
            }
            TestV.recover();
            console.log(`------------------------- accounts test over -------------------------`);
        });
    },

    flip:()=>{
        console.log(`------------------------- flip test start -------------------------`);
        TestV.backup();
        var accs=TestV.accounts(50);
        TestV.cacheSpam(accs,(map)=>{
            //console.log(`Account /Spam :${JSON.stringify(map)}`);
            var auto=Gateway.common.auto;

            var svc='vSocial',act='tread';
            var anchor='testme',block=62;

            for(var ss58 in map){
                Gateway.test.setSpam(ss58,map[ss58]);
                auto(svc,act,{anchor:anchor,block:block},(res)=>{
                    console.log(res);
                });
            }

            setTimeout(()=>{
                act='untread';
                for(var ss58 in map){
                    Gateway.test.setSpam(ss58,map[ss58]);
                    auto(svc,act,{anchor:anchor,block:block},(res)=>{
                        console.log(res);
                    });
                }
                TestV.recover();
                console.log(`------------------------- flip test over -------------------------`);
            },300);
            
        });
    },

    accountsToChain:()=>{
        console.log(`------------------------- accountsToChain test start -------------------------`);
        TestV.backup();
        var accs=TestV.accounts(50);
        TestV.cacheSpam(accs,(map)=>{
            //console.log(`Account /Spam :${JSON.stringify(map)}`);
            var auto=Gateway.common.auto;

            var svc='vSocial',act='tread';
            var anchor='testme',block=62;

            for(var ss58 in map){
                Gateway.test.setSpam(ss58,map[ss58]);
                auto(svc,act,{anchor:anchor,block:block},(res)=>{
                    console.log(res);
                });
            }
            TestV.recover();
            console.log(`------------------------- accountsToChain test over -------------------------`);
        });
    },

    //点赞/删除点赞的高并发测试，平均1～2ms请求一下。需要计算好互动操作是否可以进行
    //高速情况下，用文件缓存也是有问题的
    interaction:()=>{
        console.log(`------------------------- interaction test start -------------------------`);
        TestV.backup();
        var block=202;
        var requests=[
            ['fav','testme',block,'5D9wRNdJZ814ZQ3FrWJ9PXZnWQ3sDbLdbW3PT9Z3fekfi5wT'],
            ['unfav','testme',block,'5D9wRNdJZ814ZQ3FrWJ9PXZnWQ3sDbLdbW3PT9Z3fekfi5wT'],
            ['fav','testme',block,'5G4NYSSkwk4hk13QYa5GvxogaeNnjA8qaXBqLk9BvxYXJvaW'],
            ['unfav','testme',block,'5G4NYSSkwk4hk13QYa5GvxogaeNnjA8qaXBqLk9BvxYXJvaW'],
            ['fav','good',247,'5DAkxQvZ9JQUfZtHjYV6MbcZFvuHHBzN8HQ69xuyDUFfxwGK'],
            ['unfav','good',247,'5DAkxQvZ9JQUfZtHjYV6MbcZFvuHHBzN8HQ69xuyDUFfxwGK'],
            ['fav','testme',block,'5ECZb1Jmm8ACGXdXtBx9AbqspK2ECQ1QNnXqH9FiGLEEjJjV'],
            ['fav','testme',block,'5GWBZheNpuLXoJh3UxXwm5TFrGL2EHHusv33VwsYnmULdDHm'],
            ['unfav','testme',block,'5GWBZheNpuLXoJh3UxXwm5TFrGL2EHHusv33VwsYnmULdDHm'],
        ];
        
        
        var accs={};
        for(var i=0;i<requests.length;i++){
            var row=requests[i];
            accs[row[3]]=true;
        }

        var alist=[];
        for(var acc in accs){
            alist.push(acc);
        }
        console.log(`Account list:${JSON.stringify(alist)}`);

        TestV.cacheSpam(alist,(map)=>{
            console.log(`Account/Spam result:${JSON.stringify(map)}`);
            var svc='vSocial';
            var auto=Gateway.common.auto;
            console.log(`Ready to request ${requests.length} times.`);
            for(var i=0;i<requests.length;i++){
                var row=requests[i];
                var act=row[0],anchor=row[1],block=row[2],ss58=row[3];

                //1.设置account和spam
                Gateway.test.setSpam(ss58,map[ss58]);

                //2.请求fav部分
                //console.log(`Request start from ${self.stamp()}`);
                auto(svc,act,{anchor:anchor,block:block},(res)=>{
                    console.log(res);
                });
            }
            TestV.recover();
            console.log(`------------------------- interaction test over -------------------------`);
        });
    },


};

const Test_vSocial=self;
export default Test_vSocial;