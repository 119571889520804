
import { Row, Col,Container,Form,Button } from 'react-bootstrap';

function Progress(props) {
	const self={
    	
	};

	return (
		<Row >
			<Col lg = { 12 } xs = { 12 }></Col>
		</Row>
	);
}

export default Progress;