import { ApiPromise, WsProvider } from '@polkadot/api';
import Direct from './direct';
import Gateway from './gateway';

const config={
	endpoint:'',		//
	entry:'',			//server list entry
	setting:'',			//freeSaying config
};

let wsAPI=null;
const self={
	link:(ck) => {
		if (wsAPI === null) {
			//console.log('ready to link...');
			//console.log(JSON.stringify(config));
			try {
				const provider = new WsProvider(config.endpoint);
				ApiPromise.create({ provider: provider }).then((api) => {
					wsAPI = api;
					ck && ck(true);
				});
			} catch (error) {
				ck && ck(false);
			}
		} else {
			ck && ck(true);
		}
	},
	copy:(obj)=>{
		const res={};
		for(var k in obj){
			res[k]=obj[k];
		}
		return res;
	},
	group:(start,ck)=>{
		//console.log(start);
		//1.设置基础数据
		config.endpoint=start.node;
		config.entry=start.entry;
		config.setting=start.setting;
		RPC.start=start;

		//2.整理基础方法
		RPC.common=self.copy(Direct.common);
		RPC.extra={};
		//console.log(JSON.stringify(start));
		if(start.gateway){
			//2.1.combine basic method
			Gateway.set.endpoint(start.server);
			Gateway.set.account(start.account);
			RPC.extra=Gateway.extra;

			//2.2.using gateway common method;
			for(var k in Gateway.common){
				RPC.common[k]=Gateway.common[k];
			}
			
			Gateway.set.init(ck);
		}else{
			//2.3.use direct method as default.
			for(var k in Direct.common){
				RPC.common[k]=Direct.common[k];
			}
			//自动拼装Gateway方法的实现
			if(Gateway.common.auto) RPC.common.auto=Gateway.common.auto;

			ck && ck();
		}
	},
	destory:()=>{
		wsAPI=null;
	},
	getNodes:(start)=>{
		RPC.common.search(config.entry,(res)=>{
			var nodes={
				node:[start.node],
				gateway:[start.server],
			};
			if(res && res.raw){
				var list=res.raw;
				for(var i=0;i<list.node.length;i++){
					if(list.node[i]!==start.node) nodes.node.push(list.node[i]);
				}
				 
				for(var i=0;i<list.gateway.length;i++){
					if(list.gateway[i]!==start.server) nodes.gateway.push(list.gateway[i]);
				}
			}
			RPC.server=nodes;
			RPC.empty=false;
		});
	},
};

const RPC={
	common:{},
	extra:{},
	server:{},
	start:null,
	ready:false,		//websocket状态
	empty:true,			//是否有入口anchor
	queue:[],			//推到队列里等待处理的内容
	init:(start,cfg,ck)=>{
		//console.log('RPC init before group by '+JSON.stringify(start));
		self.group(start,() => {
			RPC.ready=false;
			RPC.empty=true;				//可用服务器的标志位

			//不启动websocket的时候，直接回调，用于提升程序启动速度
			if(!cfg.websocket){
				self.getNodes(start);
				return ck && ck(true);
			} 
			
			self.destory();
			self.link(()=>{
				RPC.ready=true;
				Direct.set.websocket(wsAPI);
				self.getNodes(start);

				//把队列里压缩的都给实现下，感觉会带来一些问题
				if(RPC.queue.length!==0){
					for(var i=0;i<RPC.queue.length;i++){
						RPC.queue.pop()();
					}
				}
				//console.log('Linked and run all queue functions.');
				ck && ck(true);
			});
		});
	},
	setQueue:(fn)=>{
		RPC.queue.push(fn);
	},
	setExtra:(name,fn) => {
		RPC.extra[name]=fn;
	},
};

export default RPC;

/****************************************/
/****************程序测试部分**************/
/****************************************/

const test={
	debug:()=>{
		//console.log(wsAPI.query.system.blockHash.multi);
		//multi only can get the latest 2000 block's hash.
		const block=1;
		wsAPI.query.system.blockHash.multi([block],function(res){
			for (var k in res) {
                const hash = res[k].toHex();
				console.log(hash);
			}
		});

		wsAPI.rpc.chain.getBlockHash(block, function (res) {
			const hash = res.toHex();
			console.log(hash);
			wsAPI.rpc.chain.getBlock(hash).then((dt) => {

			});
		});
	},
	nicks:()=>{
		console.log(wsAPI.tx.anchor);
	},
};