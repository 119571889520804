import $ from 'jquery';     //使用其中的JSONP，后面考虑替代

let account='';
let endpoint='';
let spam='';  

let dServers={};
let oServers={};

const limits={
    key:40,
    protocol:256,
    raw:4*1024*1024,
    account:48,

    comment:120,
    multi:30,               //multi最多的请求长度
};

const self = {
    limited:(key,proto,raw,account)=>{
        if(key!==undefined) return key.length>limits.key?true:false;
        if(proto!==undefined) return proto.length>limits.protocol?true:false;
        if(raw!==undefined) return raw.length>limits.raw?true:false;
        if(account!==undefined) return account.length!==limits.account?true:false;
        return false;
    },

    getAccount:()=>{
        return account;
    },
    getSpam:()=>{
        return spam;
    },
    setSpam:(acc,sp)=>{
        spam=sp;
        self.setAccount(acc);
    },

    setEndpoint:(uri)=>{
        endpoint=uri;
    },
    setAccount:(acc)=>{
        account=acc;
    },
    
    valid:(name,act,way)=>{
        var result=false;
        way=!way?"pass":way;
        switch (way) {
            case 'pass':
                if(!dServers[name] || !dServers[name].funs[act]) result=true;
                break;
            case 'order':
                if(!oServers[name] || !oServers[name].funs[act]) result=true;
                break;
            default:
                if(!dServers[name] || !dServers[name].funs[act]) result=true;
                break;
        }
        return result;
    },
    basic: function(ck) {
        const rpc = {
            "method":"system_access",
            "params":{
                "spam":spam,
            }
        };
        self.request(rpc,ck);
    },
    spam:(ck)=>{
        spam='';        //先对spam进行清除
        const rpc = {
            "method":"spam",
            "params":{"app":"freeSaying"}
        };
        self.request(rpc,function(res){
            //console.log(`Spam method: ${JSON.stringify(res)}`);
            if(!res.spam) return ck && ck(false);
            spam=res.spam;
            return ck && ck();
        });
    },

    pass:(params,ck)=>{
        const json={
            "id":"",
            "method":"call",
            "params":params,
        };
        self.request(json,ck);
    },
    order:(params,index,ck)=>{

    },
    view:(anchor,ck)=>{
        if(self.limited(anchor)) return ck && ck(false);
        const params={
            fun:"view",
            service:"vHistory",
            anchor:anchor,
            spam:spam,
        };
        self.pass(params,(res)=>{
            if(!res || !res.data) return ck && ck(false);
            return ck && ck(res.data);
        });
        
    },
    lib:(anchor,ck)=>{
        if(self.limited(anchor)) return ck && ck(false);
        const params={
            fun:"lib",
            service:"vHistory",
            anchor:anchor,
            spam:spam,
        };
        self.pass(params,(res)=>{
            return ck && ck(res.data);
        });
    },
    target:(anchor, block,ck)=>{
        if(self.limited(anchor)) return ck && ck(false);
        const params={
            fun:"target",
            service:"vHistory",
            anchor:anchor.toLocaleLowerCase(),
            block:block,
            spam:spam,
        };
        if(parseInt(block)>0) params.block=parseInt(block);
        self.pass(params,(res)=>{
            return ck && ck(res.data);
        });
    },
    //对参数进行检测的操作
    check:function(svc,act,params){
        if(!dServers[svc]) return 'No such vService:'+svc;
        if(!dServers[svc].funs || !dServers[svc].funs[act]) return 'No such call:'+act;
        if(!dServers[svc].funs[act].param) return 'No params settling';

        var ps=dServers[svc].funs[act].param;
        for(var k in params){
            if(!ps[k]) return false;
            var type=ps[k];
            var msg=self.type(params[k],type);
            if(msg!==true) return msg;
        }
        return true;
    },
    type:function(dt,type){
        //return `${dt} is not  ${type}`;
        //console.log(`Checking data ${dt} : ${type}`);
        return true;
    },
    auto:function(svc,fun,params,ck){
        //console.log(`vService request : ${svc} -> ${fun} , params : ${JSON.stringify(params)}`);
        self.init(()=>{
            const msg=self.check(svc,fun,params);
            if(msg!==true) return ck && ck({"error":msg});
            const request={
                fun:fun,
                service:svc,
                spam:spam,
            };
            for(var k in params) request[k]=params[k];
            self.pass(request,(res)=>{
                if(res.error){
                    var err=res.error;
                    err.error=true;
                    return ck && ck(err);
                }
                return ck && ck(res.data);
            });
        });
    },
    comment:(ctx,anchor,block,ck)=>{
        if(self.limited(anchor)) return ck && ck(false);
        if(ctx.length > limits.comment) return  ck && ck(false);
        self.auto('vSaying','comment',{block:block,anchor:anchor,comment:ctx},ck);
    },
    vMulti:(list,ck)=>{
        if(list.length > limits.multi) return  ck && ck(false);
        self.auto('vHistory','multi',{list:JSON.stringify(list)},ck);
    },
    multi: (list,ck,done,map)=>{
		if(!done) done=[];
		if(!map) map={};

		const row=list.pop();
		done.push(row);
		if (typeof (row) == 'string') {
			self.view(row,(data)=>{
                //console.log(data);
				map[row]=data;
				if(list.length===0) return ck && ck(self.groupMulti(done,map));
				return self.multi(list,ck,done,map);
			});
		} else {
			self.target(row[0],row[1],(data)=>{
				map[row[0]+'_'+row[1]]=data;
				if(list.length===0) return ck && ck(self.groupMulti(done,map));
				return self.multi(list,ck,done,map);
			});
		}
	},
    groupMulti:(list,map)=>{
		const arr=[];
		const format=self.format;
		for (let i = 0; i < list.length; i++) {
			const row = list[i];
			const data=map[(typeof (row) == 'string')?row:(row[0]+'_'+row[1])];
			arr.push(!data?format(list[i]):data);
		}
		return arr;
	},
	format:(anchor,obj,emtpy)=>{
		return {
			"name":!anchor?"":anchor,
			"protocol":(obj && obj.protocol)?obj.protocol:null,
			"raw":(obj && obj.raw)?obj.raw:null,
			"block":(obj && obj.block)?obj.block:0,
			"stamp":(obj && obj.stamp)?obj.stamp:0,
			"pre":(obj && obj.pre)?obj.pre:0,
			"signer":(obj && obj.signer)?obj.signer:"",
			"empty":(obj && obj.empty===false)?obj.empty:true,
			"owner":(obj && obj.owner)?obj.owner:"",
			"sell":(obj && obj.sell)?obj.sell:false,
			"cost":(obj && obj.cost)?obj.cost:0,
			"target":(obj && obj.target)?obj.target:null,
		};
	},
    history:(anchor,ck,cfg)=>{
        if(self.limited(anchor)) return ck && ck(false);
        const params={
            fun:"history",
            service:"vHistory",
            anchor:anchor,
            spam:spam,
        };
        //console.log(cfg);
        if(cfg!==undefined){
            const ss=cfg.step===undefined?10:parseInt(cfg.step);
            const pp=cfg.page===undefined?1:parseInt(cfg.page);
            params.step=ss;
            params.page=pp;
        }else{
            params.step=20;
            params.page=1;
        }    
        self.pass(params,(res)=>{
            return ck && ck(res.data);
        });
    }, 
    request:(json,ck)=>{
        //console.log(`Request:${JSON.stringify(json)}`);
        //console.log(`Account:${account}`);
        //if(json.method==='system_basic' && !!spam) return console.log('Ok');
        if(!account || account.length!==limits.account) return ck && ck(false);
        if(!spam && json.method!=='spam'){
            return self.spam(()=>{
                json.params.spam=spam;      //把spam填充到请求里;
                return self.request(json,ck);
            });
        }

        json.jsonrpc="2.0";
        if(!json.id) json.id=account;       //默认设置一个值
        self.jsonp(endpoint, json, function(res) {
            //console.log(`RPC data:${JSON.stringify(res)}`);
            if(!res.result){
                if(res.code && res.code===44){
                    spam='';
                    return self.request(json,ck);
                }else{
                    return ck && ck(res);
                }
            }else{
                return ck && ck(res.result);
            }
        });
    },
    jsonp:(server,data,ck)=>{
        var uri=server+'?';
        if(data.id) uri += `id=${data.id}&`;
        if(data.method) uri += `method=${data.method}&`;
        for(var k in data.params) uri += `${k}=${data.params[k]}&`;
        uri+='callback=?';
        console.log(`[${self.stamp()}]${uri}`);
        $.getJSON({type:'get',url:uri,async:false,success:function(res){
            if(!res.result || !res.result.success){
                console.warn('vGateway/vService Error : '+res.error.message);
            } 
            return ck && ck(res);
        }});
    },
    init:(ck)=>{
        if(Gateway.ready) return ck && ck();
        dServers={};
        oServers={};
        //console.log('ready to get avalid methods, endpoint:'+endpoint);
        self.basic((res)=>{
            const list=res.list;
            for(var k in list){
                const row=list[k];
                for(var kk in row){
                    const p=row[kk];
                    //console.log(p);
                    if(p.type==='direct'){
                        if(dServers===null || !dServers[k]) dServers[k]={active:[],funs:{}};
                        dServers[k].active.push(kk);
                        for(var fun in p.fn){
                            dServers[k].funs[fun]=p.fn[fun];
                        }
                    }

                    if(p.type==='order'){

                    }                 
                }
            }
            Gateway.vservice.pass=dServers;
            Gateway.vservice.order=oServers;
            Gateway.ready=true;
            return ck && ck();
        });
    },
    stamp:()=>{
		return new Date().getTime();
	},
};


const Gateway={
    set:{
        init:self.init,
        account:self.setAccount,
        endpoint:self.setEndpoint,
        spam:self.spam,
    },
    common:{
        search:self.view,
        target:self.target,
        history:self.history,
        multi:self.vMulti,
        auto:self.auto,
    },
    vservice:{
        pass:{},
        order:{},
    },
    extra:{
        valid:self.valid,
        free:self.free,
        lib:self.lib,
        comment:self.comment,
    },
    ready:false,
    //TODO 打包的时候，注释掉这部分的引用
    test:{ 
        getAccount:self.getAccount,
        getSpam:self.getSpam,
        setSpam:self.setSpam,
        setAccount:self.setAccount,
    },          
    
};

export default Gateway;