import Gateway from '../lib/gateway';
import TestV from './common';

const self={
    counts:()=>{
        var auto=Gateway.common.auto;
        var svc='vSaying',act='multi';
        var list=[['testme',61],['hello',83]];
        auto(svc,act,{list:JSON.stringify(list)},(res)=>{

            console.log(res);
            
        });
    },
};

const Test_vSaying=self;
export default Test_vSaying;