import STORAGE from './storage.js';

const Funs={
	loadJS: (code) => {
		const scp = document.createElement('script');
		scp.type = 'text/javascript';
		scp.crossOrigin = 'anonymous';
		scp.text = code;
		if (document.head.append) {
			document.head.append(scp);
		} else {
			document.getElementsByTagName('head')[0].appendChild(scp);
		}
		return true;
	},
	loadCSS: (code) => {
		var head = document.getElementsByTagName('head')[0];
		var style = document.createElement('style');
		var cmap = document.createTextNode(code);
		style.appendChild(cmap);
		head.appendChild(style);
		return true;
	},
	autoLoad: (code,body,RPC,ck) => {
		if (code.js) Funs.loadJS(code.js);
		if (code.css) Funs.loadCSS(code.css);
		if(body===null) return ck && ck(false);
		setTimeout(()=>{
			let raw='';
			if(body.substr(0, 2).toLowerCase()==='0x'){
				raw=decodeURIComponent(body.slice(2).replace(/\s+/g, '').replace(/[0-9a-f]{2}/g, '%$&'));
			}else{
				raw=body;
			}

			try {
				const cApp = new Function("agent", "con", "error", raw);
				if (!cApp) return false;
				cApp(RPC, "container", code.failed ? code.failed : null);
				return ck && ck(true);
			} catch (error) {
				console.log(`Eorro:${error}`);
				return ck && ck(false);
			}
			
		},0);
	},
	getAccount:(cfile)=>{
		const {account}=require('../'+cfile);
		const fa=STORAGE.getKey("signature");
		return (fa && fa.address)?fa:account;  
	},
	getStart:(cfile)=>{
		const fa=STORAGE.getKey("start");
		const {start}=require('../'+cfile);
		return !fa?start:fa;
	},
	randomName:()=>{
        return 'freeS_'+Math.ceil(Math.random()*100);
    },
};

export default Funs;