import Gateway from '../lib/gateway';

const vs=[
    '5GnaMMdmqFrUWpDsiC2FXotJ4cwnQcMDKCnygo8CviDGDsHd',
    '',
    '',
    '',
    '',
    '',
    '',
];

const self={
    status:{
        account:'',
        spam:'',
    },
    recover:()=>{
        Gateway.test.setSpam(self.status.account,self.status.spam);
    },
    backup:()=>{
        self.status.account=Gateway.test.getAccount();
        self.status.spam=Gateway.test.getSpam();
    },
    getBackupAccount:()=>{
        return self.status.account;
    },

    accounts:(n,real)=>{
        var list=[];
        for(var i=0;i<n;i++){
            list.push(real?self.validAccount():self.randomAccount());
        }
        return list;
    },
    validAccount:()=>{      //这个麻烦的，暂时不用
        var len=vs.length;
        return vs[Math.floor(Math.random()*len)];
    },
    randomAccount:(prefix)=>{
        const str58='123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
        let ss58=prefix===undefined?'5FaKe':prefix;
        const lenSS58=48,n=lenSS58-ss58.length;

        for(var i=0;i<n;i++) ss58 += str58[Math.floor(Math.random()*58)];
        return ss58;
    },
    // TODO 这个很常用的功能，后面写一下吧
    random:(min,max)=>{

    },
    merge:(list)=>{
        var map={};
        for(var i=0;i<list.length;i++){
            map[list[i]]=true;
        }

        var arr=[];
        for(var k in map){
            arr.push(k);
        }
        return arr;
    },
    cacheSpam:(accs,ck,map)=>{
        if(map===undefined){
            accs=self.merge(accs);
            map={};
        } 
        if(accs.length===0) return ck && ck(map);
        
        var ss58=accs.pop();
        Gateway.test.setAccount(ss58);
        Gateway.set.spam(()=>{
            map[ss58]=Gateway.test.getSpam();
            self.cacheSpam(accs,ck,map);
        });
    },
};

const TestV=self;
export default TestV;